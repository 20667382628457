import React from "react"
import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

import { BlackWithRedBorder } from "../infoCards/blackWithRedBorder"

const CardsWithBg = ({ data, mobile }) => {

    return (
        <CardsWithBgStyled bg={`url("${data.bg}")`}>
            <div className="container-inner">
                {mobile ?
                    <h3 className={font.oL + ' container-title'}>
                        {data.title}
                    </h3>
                    :
                    <h4 className={font.oL + ' container-title'}>
                        {data.title}
                    </h4>
                }
                <div className="container-list-advantages">
                    {data.list.map((advatange) => (
                        <BlackWithRedBorder text={advatange} mobile={mobile} />
                    ))}
                </div>
                {data.info &&
                    <p className={font.oEL + ' text-info'} dangerouslySetInnerHTML={{ __html: data.info }} />
                }
            </div>
        </CardsWithBgStyled>
    )
}

export default CardsWithBg


const CardsWithBgStyled = styled.div`
       height: 100vh;
       background-repeat: no-repeat;
       background-position: center;
       background-size: cover;
       background-image:  ${(props) => props.bg || ''};
       .container-inner{
            padding: 15vh 5vw 10vh;
       }
       .container-title{
            text-align: center;
            max-width: 35vw;
            margin: auto;
       }

       .container-list-advantages{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 15vh 0;
       }

       .text-info{
            text-align: center;
       }

       @media(max-width: 990px){
        height: 100%;
        min-height: 100vh;
        .container-title{
            max-width: 85vw;
        }
        .container-list-advantages{
            grid-template-columns: repeat(1, 1fr);
            padding: 10vh 0;
            row-gap: 10vh;
       }
       }
        `
import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import styled from "styled-components"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import CardsWithBg from "../components/campanha/cardsWithBg"
import SliderSwiper from "../components/sliderSwiper/sliderSwiper";
import DividerBorder from "../components/dividerBorder";
import SlideWithComponent from "../components/sliderSwiper/slideWithComponent"
import SwiperWithComponentMobile from "../components/sliderSwiper/swiperWithComponentMobile"
import Video from "../components/video"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

import VideoCartao from "../../static/cartao.mp4"

const CampanhaPage = ({ location, data }) => (
  <Layout>
    <SEO title="Campanha Exclusiva" image={data.campanhasJson.intro.background.childImageSharp.fluid.src} />
    {useBreakpoint().mobile ? (
      <>
        <HeaderMobile data={data.globalJson.header} />

        <SliderSwiper data={[data.campanhasJson.introMobile]} center top campanha/>

        <CardsWithBg data={data.campanhasJson.advantagesMobile} mobile/>

        <div style={{height: '10vh'}}/>

        <DividerBorder text={data.campanhasJson.divideBorder} campanha mobile={true} />

        <Video data={VideoCartao} />

        <SwiperWithComponentMobile slide={data.campanhasJson.conditionsMobile} campanha  mobile location={location} justify small/>

        <FooterMobile data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
      </>
    ) : (
      <>
        <Header data={data.globalJson.header} />

        <SliderSwiper data={[data.campanhasJson.intro]} center oneSlideBorder={false} top />
        
        <CardsWithBg data={data.campanhasJson.advantages} />

        <DividerBorder text={data.campanhasJson.divideBorder} mobile={false}  campanha/>

        <Video data={VideoCartao} />

        <SliderSwiper data={[data.campanhasJson.conditions]} oneSlideBorder={true} description campanha/>

        <Footer data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
      </>
    )}
  </Layout>
)

export default CampanhaPage

export const Json = graphql`
  query campanhas {
    campanhasJson {
      divideBorder{
        title
        subtitle
      }
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      advantages{
        title
        list
        info
        bg
      }
      advantagesMobile{
        title
        list
        info
        bg
      }
      conditions {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400, toFormatBase64: NO_CHANGE) {
              base64
              tracedSVG
              srcWebp
              srcSetWebp
              originalImg
              originalName
            }
          }
        }
        subtitle
        description
      }
      conditionsMobile{
            btnClass
            btnLink
            description
            btnText
            id
            title
            subtitle
            video
            img {
              childImageSharp {
                fluid(quality: 100, maxWidth: 3400) {
                  src
                  srcWebp
                }
              }
            }
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
        
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const CampanhaPageStyled = styled.div`
    .containers-multimedia-text{
      padding: 15vh 5vw 10vh 5vw;
    }
    hr{
      margin-top: 5vh;
      margin-bottom: 5vh;
      height: 1px;
      background-color: #5A5A5A;
      border: none;
    }
`

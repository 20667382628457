import React from "react"
import styled from "styled-components"

const Video = ({ data }) => {

  return (
    <VideoStyled >
      <video  controls poster="/images/cartao/cartao.png">
        <source src={data} type="video/mp4" />
      </video>

    </VideoStyled>
  )
}

export default Video


const VideoStyled = styled.div`
        column-gap: 5%;
        align-items: center;
        padding-top: 0vh;
        padding-bottom: 0vh;
        width: 100%;
        margin: auto;
        height: 85vh;
        align-items: center;
        align-items: center;
        display: grid;


        @media (max-width: 990px){
          display: block;
        min-height: 100%;
        height: 50vh;
        padding-top: 0vh;
}

        iframe, video{
          height: 70%;
        width: 70%;
        margin: auto;
        @media (min-width: 768px) and (max-width: 1024px) {
          min - height: 50vh;
    }
        @media (max-width: 990px){
          height: 100%;
        width: 100%;
    }
  }

        `
import styled, { css } from "styled-components"
import font from "../../fonts/fonts.module.scss"
import React from "react"

export const Button = styled.button`
  background: transparent;
  user-select: none;
  outline: none;
  cursor: pointer;
`
export const BlackWithRedBorder = ({ text, mobile }) => {

  return (
    <BlackWithRedBorderStyled >
      {mobile ?
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="235.055" height="198.694" viewBox="0 0 235.055 198.694">
            <g id="Grupo_685" data-name="Grupo 685" transform="translate(1.055 1.001)">
              <g id="Grupo_420" data-name="Grupo 420">
                <path id="Caminho_252" data-name="Caminho 252" d="M300,1136.893a3.185,3.185,0,0,0,1.471-1.035,10.879,10.879,0,0,0,1.857-4.746,58.826,58.826,0,0,0,.551-13.711l-7.208-118.928c-2.129-35.172-11.935-57.841-24.834-57.427l-15.132.484-185.675,1.7,1.035,19.3,9.415,155.384a57.916,57.916,0,0,0,2.288,13.724q1.8,5.787,3.818,6.1Z" transform="translate(-71.033 -941.041)" fill="rgba(0,0,0,0.7)" stroke="#ab2c29" stroke-width="2" />
              </g>
            </g>
          </svg>
          <h3 className={font.oL + ' intro-text'}>{text}</h3>
        </>
        :
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="313.055" height="264.539" viewBox="0 0 313.055 264.539">
            <g id="Grupo_6" data-name="Grupo 6" transform="translate(-1085.628 -879.999)">
              <g id="Grupo_420" data-name="Grupo 420" transform="translate(1086.683 881)">
                <path id="Caminho_252" data-name="Caminho 252" d="M376.652,1202.457a4.251,4.251,0,0,0,1.964-1.381c1.019-1.169,1.848-3.292,2.479-6.335a78.521,78.521,0,0,0,.735-18.3l-9.621-158.74c-2.841-46.946-15.93-77.2-33.148-76.652l-20.2.646L71.033,943.969l1.381,25.754,12.566,207.4a77.305,77.305,0,0,0,3.054,18.318q2.408,7.724,5.1,8.137Z" transform="translate(-71.033 -941.041)" fill="rgba(0,0,0,0.7)" stroke="#ab2c29" stroke-width="2" />
              </g>
            </g>
          </svg>
          <h4 className={font.oL + ' intro-text'}>{text}</h4>
        </>
      }
    </BlackWithRedBorderStyled>
  )
}


const BlackWithRedBorderStyled = styled.div`
height: 25vh;
width: 25vw;
position: relative;

.intro-text{
  text-align: center;
  height: 100%;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  display: grid;  
  z-index: 99;
  position: relative;
  transition: all 0.5s ease;

  position: absolute;
  top: 0;
  z-index: 1;
  text-align: center;

  color: #fff !important;
  width: 50%;
  margin: auto !important;
  position: relative;
}

svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 25vw;
  height: 100%;
  transition: all  0.5s ease;
}

path {
  strokeWidth: 2;
  stroke: #AB2C29;
  fill: black;
  transition: all  0.5s ease;
}

@media(max-width: 990px){
  height: 25vh;
  width: 90vw;
  svg {
    width: 90vw;
  }
}


`
